/* Navbar */

nav {
    background: #222;

    .nav-wrapper {
        display: flex;
        align-items: center;

        #logo-link {
            display: flex;
            align-items: center;
        }

        img {
            margin-left: 170px;
            width: 130px;
        }
    }

    span {
        font-size: 1.3em;
        padding-left: 15px;
    }

    #navbarUser {
        align-self: end;
        margin: 0 30px 0 auto;

        .material-icons {
            font-size: 3em;
        }

        #user-dropdown {
            background: #222;
            top: 60px !important;

            li {
                &:hover {
                    background: #222;
                }

                a:hover {
                    color: #efefef;
                }
            }
            li:hover {
                background: #222;
            }
        }

        .navbar-account-wrapper {
            display: flex;
            flex-wrap: wrap;

            .material-icons:last-child {
                margin: 0;
                font-size: 30px;
            }
        }

        .dropdown-content li > a,
        .dropdown-content li > span {
            color: var(--white-header);
        }
    }
}

.sidenav {
    background: #222;

    .img-avatar {
        margin: 0 auto;
    }

    .name {
        color: var(--white-header);
    }

    .email {
        color: var(--white-subheader);
    }

    .subheader {
        color: var(--white-header) !important;
        font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen,
            Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-size: 12px !important;
        text-transform: uppercase;
    }

    .sidenav-item {
        color: var(--white-subheader) !important;
    }

    li > a > i,
    li > a > [class^='mdi-'],
    li > a li > a > [class*='mdi-'],
    li > a > i.material-icons {
        color: var(--white-subheader);
    }

    .user-view {
        margin-top: 80px;
        padding: 5px !important;
    }

    .sidenav-configure {
        padding: 15px 20px 0px;
    }

    .active,
    .active i {
        color: var(--white-header) !important;
    }

    .active i {
        display: inline-block;
        position: relative;
    }

    .active i:after {
        position: absolute;
        content: '';
        border-bottom: 2px solid var(--white-header);
        width: 70%;
        bottom: 0px;
        margin-bottom: 8px;
        left: 12%;
        animation: fadeInLeft;
        animation-duration: 0.2s;
    }
}

/* End Navbar */

/* Breadcrumbs */

.breadcrumb {
    text-transform: capitalize;
}

// .breadcrumb {
//     font-size: 14px;

//     .breadcrumb-item {
//         color: #95949e;
//         text-transform: capitalize;

//         a {
//             color: #95949e;

//             &:hover {
//                 text-decoration: none;
//                 color: #a7a6ae;
//             }
//         }
//     }
// }

// .breadcrumb-item + .breadcrumb-item::before {
//     display: inline-block;
//     padding-right: 0.5rem;
//     color: #95949e;
//     content: '\E5CC';
//     font-family: 'Material Icons';
// }

.breadcrumbs {
    padding-top: 40px !important;

    span,
    a {
        font-size: 14px;
        color: #222;

        &:before {
            font-size: 14px;
            color: #222;
            position: relative;
            top: 2px;
        }

        &:last-child {
            color: #222;
        }
    }
}

.topmenu-title {
    margin-bottom: 0;
}

/* End Breadcrumbs */

/* Table */

.switch label input[type='checkbox']:checked + .lever {
    background-color: #bbb;
}

.switch label input[type='checkbox']:checked + .lever:after {
    background-color: #e5e5e5;
}

.MuiMenu-paper {
    overflow: inherit !important;
}

// filter menu
.MUIDataTableFilter-title-327 {
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-left: 7px !important;
}

.filter-menu {
    min-width: 300px;
    max-width: 300px;
}

.react-select {
    min-width: 250px;
    max-width: 250px;
}

/* End Table */

/* Tab Bar */

#tabbar {
    margin-bottom: 3em;

    .tabs .tab a {
        color: #039be5;
        font-weight: 500;
    }

    .tabs .indicator {
        background-color: #039be5;
    }

    .tabs .tab a:focus,
    .tabs .tab a:focus.active {
        background-color: #e8f7ff;
    }
}

/* End Tab Bar */

/* Errors */

.errors-row {
    margin: 1em 0 0 0;
}

.chip {
    height: 100%;
    padding: 5px 5px 5px 20px;
    border-radius: 5px;
}

/* End Errors */

/* NotFound / 404 */

#notfound {
    text-align: center;
    padding-top: 10vh;
    h1 {
        font-size: 116px;
        font-weight: 400;
        color: var(--white-subheader);
        text-transform: uppercase;
    }
    h2 {
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: var(--black-header);
    }
    p {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 50px;
        color: var(--black-header);
    }
    button {
        background: #242640;
    }
}

/* End NotFound / 404 */

/* Media queries */

@media only screen and (max-width: 600px) {
    .nav-wrapper {
        img {
            display: none;
        }

        .nav-wrapper-title {
            font-size: 14px;
        }

        #navbarUser {
            margin: 0 0 0 auto;
        }
    }

    #notfound {
        h1 {
            font-size: 90px;
        }
        h2 {
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
    }
}

/* End Media queries */

/* Loading Error Icons */

@-webkit-keyframes swal2-show {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    80% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes swal2-show {
    0% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }
    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    80% {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@-webkit-keyframes swal2-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}
@keyframes swal2-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }
}
@-webkit-keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: 0.0625em;
        width: 0;
    }
    54% {
        top: 1.0625em;
        left: 0.125em;
        width: 0;
    }
    70% {
        top: 2.1875em;
        left: -0.375em;
        width: 3.125em;
    }
    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em;
    }
    100% {
        top: 2.8125em;
        left: 0.875em;
        width: 1.5625em;
    }
}
@keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: 0.0625em;
        width: 0;
    }
    54% {
        top: 1.0625em;
        left: 0.125em;
        width: 0;
    }
    70% {
        top: 2.1875em;
        left: -0.375em;
        width: 3.125em;
    }
    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em;
    }
    100% {
        top: 2.8125em;
        left: 0.875em;
        width: 1.5625em;
    }
}
@-webkit-keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em;
    }
    100% {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
    }
}
@keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0;
    }
    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em;
    }
    100% {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
    }
}
@-webkit-keyframes swal2-rotate-success-circular-line {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
    100% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
}
@keyframes swal2-rotate-success-circular-line {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
    100% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg);
    }
}
@-webkit-keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0;
    }
    50% {
        margin-top: 1.625em;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0;
    }
    80% {
        margin-top: -0.375em;
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }
    100% {
        margin-top: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0;
    }
    50% {
        margin-top: 1.625em;
        -webkit-transform: scale(0.4);
        transform: scale(0.4);
        opacity: 0;
    }
    80% {
        margin-top: -0.375em;
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }
    100% {
        margin-top: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@-webkit-keyframes swal2-animate-error-icon {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}
@keyframes swal2-animate-error-icon {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}
body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast {
    flex-direction: column;
    align-items: stretch;
}
body.swal2-toast-shown.swal2-has-input
    > .swal2-container
    > .swal2-toast
    .swal2-actions {
    flex: 1;
    align-self: stretch;
    justify-content: flex-end;
    height: 2.2em;
}
body.swal2-toast-shown.swal2-has-input
    > .swal2-container
    > .swal2-toast
    .swal2-loading {
    justify-content: center;
}
body.swal2-toast-shown.swal2-has-input
    > .swal2-container
    > .swal2-toast
    .swal2-input {
    height: 2em;
    margin: 0.3125em auto;
    font-size: 1em;
}
body.swal2-toast-shown.swal2-has-input
    > .swal2-container
    > .swal2-toast
    .swal2-validationerror {
    font-size: 1em;
}
body.swal2-toast-shown > .swal2-container {
    position: fixed;
    background-color: transparent;
}
body.swal2-toast-shown > .swal2-container.swal2-shown {
    background-color: transparent;
}
body.swal2-toast-shown > .swal2-container.swal2-top {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
body.swal2-toast-shown > .swal2-container.swal2-top-end,
body.swal2-toast-shown > .swal2-container.swal2-top-right {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
}
body.swal2-toast-shown > .swal2-container.swal2-top-left,
body.swal2-toast-shown > .swal2-container.swal2-top-start {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
}
body.swal2-toast-shown > .swal2-container.swal2-center-left,
body.swal2-toast-shown > .swal2-container.swal2-center-start {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
body.swal2-toast-shown > .swal2-container.swal2-center {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
body.swal2-toast-shown > .swal2-container.swal2-center-end,
body.swal2-toast-shown > .swal2-container.swal2-center-right {
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
body.swal2-toast-shown > .swal2-container.swal2-bottom-left,
body.swal2-toast-shown > .swal2-container.swal2-bottom-start {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
}
body.swal2-toast-shown > .swal2-container.swal2-bottom {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
body.swal2-toast-shown > .swal2-container.swal2-bottom-end,
body.swal2-toast-shown > .swal2-container.swal2-bottom-right {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
}
.swal2-popup.swal2-toast {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 0.625em;
    box-shadow: 0 0 0.625em #d9d9d9;
    overflow-y: hidden;
}
.swal2-popup.swal2-toast .swal2-header {
    flex-direction: row;
}
.swal2-popup.swal2-toast .swal2-title {
    justify-content: flex-start;
    margin: 0 0.6em;
    font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-close {
    position: initial;
}
.swal2-popup.swal2-toast .swal2-content {
    justify-content: flex-start;
    font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-icon {
    width: 2em;
    min-width: 2em;
    height: 2em;
    margin: 0;
}
.swal2-popup.swal2-toast .swal2-icon-text {
    font-size: 2em;
    font-weight: 700;
    line-height: 1em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
    width: 2em;
    height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
    top: 0.875em;
    width: 1.375em;
}
.swal2-popup.swal2-toast
    .swal2-icon.swal2-error
    [class^='swal2-x-mark-line'][class$='left'] {
    left: 0.3125em;
}
.swal2-popup.swal2-toast
    .swal2-icon.swal2-error
    [class^='swal2-x-mark-line'][class$='right'] {
    right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
    height: auto;
    margin: 0 0.3125em;
}
.swal2-popup.swal2-toast .swal2-styled {
    margin: 0 0.3125em;
    padding: 0.3125em 0.625em;
    font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
    box-shadow: 0 0 0 0.0625em #fff, 0 0 0 0.125em rgba(50, 100, 150, 0.4);
}
.swal2-popup.swal2-toast .swal2-success {
    border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'] {
    position: absolute;
    width: 2em;
    height: 2.8125em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 50%;
}
.swal2-popup.swal2-toast
    .swal2-success
    [class^='swal2-success-circular-line'][class$='left'] {
    top: -0.25em;
    left: -0.9375em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 2em 2em;
    transform-origin: 2em 2em;
    border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast
    .swal2-success
    [class^='swal2-success-circular-line'][class$='right'] {
    top: -0.25em;
    left: 0.9375em;
    -webkit-transform-origin: 0 2em;
    transform-origin: 0 2em;
    border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
    width: 2em;
    height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
    top: 0;
    left: 0.4375em;
    width: 0.4375em;
    height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'] {
    height: 0.3125em;
}
.swal2-popup.swal2-toast
    .swal2-success
    [class^='swal2-success-line'][class$='tip'] {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
}
.swal2-popup.swal2-toast
    .swal2-success
    [class^='swal2-success-line'][class$='long'] {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
}
.swal2-popup.swal2-toast.swal2-show {
    -webkit-animation: showSweetToast 0.5s;
    animation: showSweetToast 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
    -webkit-animation: hideSweetToast 0.2s forwards;
    animation: hideSweetToast 0.2s forwards;
}
.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-tip {
    -webkit-animation: animate-toast-success-tip 0.75s;
    animation: animate-toast-success-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-long {
    -webkit-animation: animate-toast-success-long 0.75s;
    animation: animate-toast-success-long 0.75s;
}
@-webkit-keyframes showSweetToast {
    0% {
        -webkit-transform: translateY(-0.625em) rotateZ(2deg);
        transform: translateY(-0.625em) rotateZ(2deg);
        opacity: 0;
    }
    33% {
        -webkit-transform: translateY(0) rotateZ(-2deg);
        transform: translateY(0) rotateZ(-2deg);
        opacity: 0.5;
    }
    66% {
        -webkit-transform: translateY(0.3125em) rotateZ(2deg);
        transform: translateY(0.3125em) rotateZ(2deg);
        opacity: 0.7;
    }
    100% {
        -webkit-transform: translateY(0) rotateZ(0);
        transform: translateY(0) rotateZ(0);
        opacity: 1;
    }
}
@keyframes showSweetToast {
    0% {
        -webkit-transform: translateY(-0.625em) rotateZ(2deg);
        transform: translateY(-0.625em) rotateZ(2deg);
        opacity: 0;
    }
    33% {
        -webkit-transform: translateY(0) rotateZ(-2deg);
        transform: translateY(0) rotateZ(-2deg);
        opacity: 0.5;
    }
    66% {
        -webkit-transform: translateY(0.3125em) rotateZ(2deg);
        transform: translateY(0.3125em) rotateZ(2deg);
        opacity: 0.7;
    }
    100% {
        -webkit-transform: translateY(0) rotateZ(0);
        transform: translateY(0) rotateZ(0);
        opacity: 1;
    }
}
@-webkit-keyframes hideSweetToast {
    0% {
        opacity: 1;
    }
    33% {
        opacity: 0.5;
    }
    100% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
        opacity: 0;
    }
}
@keyframes hideSweetToast {
    0% {
        opacity: 1;
    }
    33% {
        opacity: 0.5;
    }
    100% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
        opacity: 0;
    }
}
@-webkit-keyframes animate-toast-success-tip {
    0% {
        top: 0.5625em;
        left: 0.0625em;
        width: 0;
    }
    54% {
        top: 0.125em;
        left: 0.125em;
        width: 0;
    }
    70% {
        top: 0.625em;
        left: -0.25em;
        width: 1.625em;
    }
    84% {
        top: 1.0625em;
        left: 0.75em;
        width: 0.5em;
    }
    100% {
        top: 1.125em;
        left: 0.1875em;
        width: 0.75em;
    }
}
@keyframes animate-toast-success-tip {
    0% {
        top: 0.5625em;
        left: 0.0625em;
        width: 0;
    }
    54% {
        top: 0.125em;
        left: 0.125em;
        width: 0;
    }
    70% {
        top: 0.625em;
        left: -0.25em;
        width: 1.625em;
    }
    84% {
        top: 1.0625em;
        left: 0.75em;
        width: 0.5em;
    }
    100% {
        top: 1.125em;
        left: 0.1875em;
        width: 0.75em;
    }
}
@-webkit-keyframes animate-toast-success-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0;
    }
    65% {
        top: 1.25em;
        right: 0.9375em;
        width: 0;
    }
    84% {
        top: 0.9375em;
        right: 0;
        width: 1.125em;
    }
    100% {
        top: 0.9375em;
        right: 0.1875em;
        width: 1.375em;
    }
}
@keyframes animate-toast-success-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0;
    }
    65% {
        top: 1.25em;
        right: 0.9375em;
        width: 0;
    }
    84% {
        top: 0.9375em;
        right: 0;
        width: 1.125em;
    }
    100% {
        top: 0.9375em;
        right: 0.1875em;
        width: 1.375em;
    }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: hidden;
}
body.swal2-height-auto {
    height: auto !important;
}
body.swal2-no-backdrop .swal2-shown {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    background-color: transparent;
}
body.swal2-no-backdrop .swal2-shown > .swal2-modal {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
body.swal2-no-backdrop .swal2-shown.swal2-top {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-shown.swal2-top-left,
body.swal2-no-backdrop .swal2-shown.swal2-top-start {
    top: 0;
    left: 0;
}
body.swal2-no-backdrop .swal2-shown.swal2-top-end,
body.swal2-no-backdrop .swal2-shown.swal2-top-right {
    top: 0;
    right: 0;
}
body.swal2-no-backdrop .swal2-shown.swal2-center {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-shown.swal2-center-left,
body.swal2-no-backdrop .swal2-shown.swal2-center-start {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-shown.swal2-center-end,
body.swal2-no-backdrop .swal2-shown.swal2-center-right {
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-shown.swal2-bottom {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-shown.swal2-bottom-left,
body.swal2-no-backdrop .swal2-shown.swal2-bottom-start {
    bottom: 0;
    left: 0;
}
body.swal2-no-backdrop .swal2-shown.swal2-bottom-end,
body.swal2-no-backdrop .swal2-shown.swal2-bottom-right {
    right: 0;
    bottom: 0;
}
.swal2-container {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: transparent;
    z-index: 1060;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-top {
    align-items: flex-start;
}
.swal2-container.swal2-top-left,
.swal2-container.swal2-top-start {
    align-items: flex-start;
    justify-content: flex-start;
}
.swal2-container.swal2-top-end,
.swal2-container.swal2-top-right {
    align-items: flex-start;
    justify-content: flex-end;
}
.swal2-container.swal2-center {
    align-items: center;
}
.swal2-container.swal2-center-left,
.swal2-container.swal2-center-start {
    align-items: center;
    justify-content: flex-start;
}
.swal2-container.swal2-center-end,
.swal2-container.swal2-center-right {
    align-items: center;
    justify-content: flex-end;
}
.swal2-container.swal2-bottom {
    align-items: flex-end;
}
.swal2-container.swal2-bottom-left,
.swal2-container.swal2-bottom-start {
    align-items: flex-end;
    justify-content: flex-start;
}
.swal2-container.swal2-bottom-end,
.swal2-container.swal2-bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-self: stretch;
    justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-content: center;
    justify-content: center;
}
.swal2-container.swal2-grow-column {
    flex: 1;
    flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-bottom,
.swal2-container.swal2-grow-column.swal2-center,
.swal2-container.swal2-grow-column.swal2-top {
    align-items: center;
}
.swal2-container.swal2-grow-column.swal2-bottom-left,
.swal2-container.swal2-grow-column.swal2-bottom-start,
.swal2-container.swal2-grow-column.swal2-center-left,
.swal2-container.swal2-grow-column.swal2-center-start,
.swal2-container.swal2-grow-column.swal2-top-left,
.swal2-container.swal2-grow-column.swal2-top-start {
    align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-bottom-end,
.swal2-container.swal2-grow-column.swal2-bottom-right,
.swal2-container.swal2-grow-column.swal2-center-end,
.swal2-container.swal2-grow-column.swal2-center-right,
.swal2-container.swal2-grow-column.swal2-top-end,
.swal2-container.swal2-grow-column.swal2-top-right {
    align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
    display: flex !important;
    flex: 1;
    align-content: center;
    justify-content: center;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right)
    > .swal2-modal {
    margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .swal2-container .swal2-modal {
        margin: 0 !important;
    }
}
.swal2-container.swal2-fade {
    transition: background-color 0.1s;
}
.swal2-container.swal2-shown {
    background-color: rgba(0, 0, 0, 0.4);
}
.swal2-popup {
    display: none;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border-radius: 0.3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
    box-sizing: border-box;
}
.swal2-popup:focus {
    outline: 0;
}
.swal2-popup.swal2-loading {
    overflow-y: hidden;
}
.swal2-popup .swal2-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.swal2-popup .swal2-title {
    display: block;
    position: relative;
    max-width: 100%;
    margin: 0 0 0.4em;
    padding: 0;
    color: #595959;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
}
.swal2-popup .swal2-actions {
    align-items: center;
    justify-content: center;
    margin: 1.25em auto 0;
}
.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
    opacity: 0.4;
}
.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
    width: 2.5em;
    height: 2.5em;
    margin: 0.46875em;
    padding: 0;
    border: 0.25em solid transparent;
    border-radius: 100%;
    border-color: transparent;
    background-color: transparent !important;
    color: transparent;
    cursor: default;
    box-sizing: border-box;
    -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
    margin-right: 30px;
    margin-left: 30px;
}
.swal2-popup
    .swal2-actions.swal2-loading
    :not(.swal2-styled).swal2-confirm::after {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 5px;
    border: 3px solid #999;
    border-radius: 50%;
    border-right-color: transparent;
    box-shadow: 1px 1px 1px #fff;
    content: '';
    -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
}
.swal2-popup .swal2-styled {
    margin: 0 0.3125em;
    padding: 0.625em 2em;
    font-weight: 500;
    box-shadow: none;
}
.swal2-popup .swal2-styled:not([disabled]) {
    cursor: pointer;
}
.swal2-popup .swal2-styled.swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #3085d6;
    color: #fff;
    font-size: 1.0625em;
}
.swal2-popup .swal2-styled.swal2-cancel {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #aaa;
    color: #fff;
    font-size: 1.0625em;
}
.swal2-popup .swal2-styled:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px rgba(50, 100, 150, 0.4);
}
.swal2-popup .swal2-styled::-moz-focus-inner {
    border: 0;
}
.swal2-popup .swal2-footer {
    justify-content: center;
    margin: 1.25em 0 0;
    padding-top: 1em;
    border-top: 1px solid #eee;
    color: #545454;
    font-size: 1em;
}
.swal2-popup .swal2-image {
    max-width: 100%;
    margin: 1.25em auto;
}
.swal2-popup .swal2-close {
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    width: 1.2em;
    height: 1.2em;
    padding: 0;
    transition: color 0.1s ease-out;
    border: none;
    border-radius: 0;
    background: 0 0;
    color: #ccc;
    font-family: serif;
    font-size: 2.5em;
    line-height: 1.2;
    cursor: pointer;
    overflow: hidden;
}
.swal2-popup .swal2-close:hover {
    -webkit-transform: none;
    transform: none;
    color: #f27474;
}
.swal2-popup > .swal2-checkbox,
.swal2-popup > .swal2-file,
.swal2-popup > .swal2-input,
.swal2-popup > .swal2-radio,
.swal2-popup > .swal2-select,
.swal2-popup > .swal2-textarea {
    display: none;
}
.swal2-popup .swal2-content {
    justify-content: center;
    margin: 0;
    padding: 0;
    color: #545454;
    font-size: 1.125em;
    font-weight: 300;
    line-height: normal;
    word-wrap: break-word;
}
.swal2-popup #swal2-content {
    text-align: center;
}
.swal2-popup .swal2-checkbox,
.swal2-popup .swal2-file,
.swal2-popup .swal2-input,
.swal2-popup .swal2-radio,
.swal2-popup .swal2-select,
.swal2-popup .swal2-textarea {
    margin: 1em auto;
}
.swal2-popup .swal2-file,
.swal2-popup .swal2-input,
.swal2-popup .swal2-textarea {
    width: 100%;
    transition: border-color 0.3s, box-shadow 0.3s;
    border: 1px solid #d9d9d9;
    border-radius: 0.1875em;
    font-size: 1.125em;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
}
.swal2-popup .swal2-file.swal2-inputerror,
.swal2-popup .swal2-input.swal2-inputerror,
.swal2-popup .swal2-textarea.swal2-inputerror {
    border-color: #f27474 !important;
    box-shadow: 0 0 2px #f27474 !important;
}
.swal2-popup .swal2-file:focus,
.swal2-popup .swal2-input:focus,
.swal2-popup .swal2-textarea:focus {
    border: 1px solid #b4dbed;
    outline: 0;
    box-shadow: 0 0 3px #c4e6f5;
}
.swal2-popup .swal2-file::-webkit-input-placeholder,
.swal2-popup .swal2-input::-webkit-input-placeholder,
.swal2-popup .swal2-textarea::-webkit-input-placeholder {
    color: #ccc;
}
.swal2-popup .swal2-file:-ms-input-placeholder,
.swal2-popup .swal2-input:-ms-input-placeholder,
.swal2-popup .swal2-textarea:-ms-input-placeholder {
    color: #ccc;
}
.swal2-popup .swal2-file::-ms-input-placeholder,
.swal2-popup .swal2-input::-ms-input-placeholder,
.swal2-popup .swal2-textarea::-ms-input-placeholder {
    color: #ccc;
}
.swal2-popup .swal2-file::placeholder,
.swal2-popup .swal2-input::placeholder,
.swal2-popup .swal2-textarea::placeholder {
    color: #ccc;
}
.swal2-popup .swal2-range input {
    width: 80%;
}
.swal2-popup .swal2-range output {
    width: 20%;
    font-weight: 600;
    text-align: center;
}
.swal2-popup .swal2-range input,
.swal2-popup .swal2-range output {
    height: 2.625em;
    margin: 1em auto;
    padding: 0;
    font-size: 1.125em;
    line-height: 2.625em;
}
.swal2-popup .swal2-input {
    height: 2.625em;
    padding: 0.75em;
}
.swal2-popup .swal2-input[type='number'] {
    max-width: 10em;
}
.swal2-popup .swal2-file {
    font-size: 1.125em;
}
.swal2-popup .swal2-textarea {
    height: 6.75em;
    padding: 0.75em;
}
.swal2-popup .swal2-select {
    min-width: 50%;
    max-width: 100%;
    padding: 0.375em 0.625em;
    color: #545454;
    font-size: 1.125em;
}
.swal2-popup .swal2-checkbox,
.swal2-popup .swal2-radio {
    align-items: center;
    justify-content: center;
}
.swal2-popup .swal2-checkbox label,
.swal2-popup .swal2-radio label {
    margin: 0 0.6em;
    font-size: 1.125em;
}
.swal2-popup .swal2-checkbox input,
.swal2-popup .swal2-radio input {
    margin: 0 0.4em;
}
.swal2-popup .swal2-validationerror {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0.625em;
    background: #f0f0f0;
    color: #666;
    font-size: 1em;
    font-weight: 300;
    overflow: hidden;
}
.swal2-popup .swal2-validationerror::before {
    display: inline-block;
    width: 1.5em;
    min-width: 1.5em;
    height: 1.5em;
    margin: 0 0.625em;
    border-radius: 50%;
    background-color: #f27474;
    color: #fff;
    font-weight: 600;
    line-height: 1.5em;
    text-align: center;
    content: '!';
    zoom: normal;
}
@supports (-ms-accelerator: true) {
    .swal2-range input {
        width: 100% !important;
    }
    .swal2-range output {
        display: none;
    }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .swal2-range input {
        width: 100% !important;
    }
    .swal2-range output {
        display: none;
    }
}
@-moz-document url-prefix() {
    .swal2-close:focus {
        outline: 2px solid rgba(50, 100, 150, 0.4);
    }
}
.swal2-icon {
    position: relative;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: 0.25em solid transparent;
    border-radius: 50%;
    line-height: 5em;
    cursor: default;
    box-sizing: content-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    zoom: normal;
}
.swal2-icon-text {
    font-size: 3.75em;
}
.swal2-icon.swal2-error {
    border-color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
    position: relative;
    flex-grow: 1;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #f27474;
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
    left: 1.0625em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
    right: 1em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86;
}
.swal2-icon.swal2-info {
    border-color: #9de0f6;
    color: #3fc3ee;
}
.swal2-icon.swal2-question {
    border-color: #c9dae1;
    color: #87adbd;
}
.swal2-icon.swal2-success {
    border-color: #a5dc86;
}
.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 50%;
}
.swal2-icon.swal2-success
    [class^='swal2-success-circular-line'][class$='left'] {
    top: -0.4375em;
    left: -2.0635em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 3.75em 3.75em;
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success
    [class^='swal2-success-circular-line'][class$='right'] {
    top: -0.6875em;
    left: 1.875em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0 3.75em;
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
    position: absolute;
    top: -0.25em;
    left: -0.25em;
    width: 100%;
    height: 100%;
    border: 0.25em solid rgba(165, 220, 134, 0.3);
    border-radius: 50%;
    z-index: 2;
    box-sizing: content-box;
}
.swal2-icon.swal2-success .swal2-success-fix {
    position: absolute;
    top: 0.5em;
    left: 1.625em;
    width: 0.4375em;
    height: 5.625em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    z-index: 1;
}
.swal2-icon.swal2-success [class^='swal2-success-line'] {
    display: block;
    position: absolute;
    height: 0.3125em;
    border-radius: 0.125em;
    background-color: #a5dc86;
    z-index: 2;
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
    top: 2.875em;
    left: 0.875em;
    width: 1.5625em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.swal2-progresssteps {
    align-items: center;
    margin: 0 0 1.25em;
    padding: 0;
    font-weight: 600;
}
.swal2-progresssteps li {
    display: inline-block;
    position: relative;
}
.swal2-progresssteps .swal2-progresscircle {
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background: #3085d6;
    color: #fff;
    line-height: 2em;
    text-align: center;
    z-index: 20;
}
.swal2-progresssteps .swal2-progresscircle:first-child {
    margin-left: 0;
}
.swal2-progresssteps .swal2-progresscircle:last-child {
    margin-right: 0;
}
.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
    background: #3085d6;
}
.swal2-progresssteps
    .swal2-progresscircle.swal2-activeprogressstep
    ~ .swal2-progresscircle {
    background: #add8e6;
}
.swal2-progresssteps
    .swal2-progresscircle.swal2-activeprogressstep
    ~ .swal2-progressline {
    background: #add8e6;
}
.swal2-progresssteps .swal2-progressline {
    width: 2.5em;
    height: 0.4em;
    margin: 0 -1px;
    background: #3085d6;
    z-index: 10;
}
[class^='swal2'] {
    -webkit-tap-highlight-color: transparent;
}
.swal2-show {
    -webkit-animation: swal2-show 0.3s;
    animation: swal2-show 0.3s;
}
.swal2-show.swal2-noanimation {
    -webkit-animation: none;
    animation: none;
}
.swal2-hide {
    -webkit-animation: swal2-hide 0.15s forwards;
    animation: swal2-hide 0.15s forwards;
}
.swal2-hide.swal2-noanimation {
    -webkit-animation: none;
    animation: none;
}
[dir='rtl'] .swal2-close {
    right: auto;
    left: 0;
}
.swal2-animate-success-icon .swal2-success-line-tip {
    -webkit-animation: swal2-animate-success-line-tip 0.75s;
    animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-animate-success-icon .swal2-success-line-long {
    -webkit-animation: swal2-animate-success-line-long 0.75s;
    animation: swal2-animate-success-line-long 0.75s;
}
.swal2-animate-success-icon .swal2-success-circular-line-right {
    -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
    animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-animate-error-icon {
    -webkit-animation: swal2-animate-error-icon 0.5s;
    animation: swal2-animate-error-icon 0.5s;
}
.swal2-animate-error-icon .swal2-x-mark {
    -webkit-animation: swal2-animate-error-x-mark 0.5s;
    animation: swal2-animate-error-x-mark 0.5s;
}
@-webkit-keyframes swal2-rotate-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes swal2-rotate-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* End Loading Error Icons */
