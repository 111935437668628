/* Text */

.add-role-assignment {
    float: right;

    .material-icons {
        position: relative;
        bottom: 1px;
    }

    span:last-child {
        padding-left: 5px;

        &:hover {
            text-decoration: underline;
        }
    }

    &:hover {
        cursor: pointer;
    }
}

/* End Text */

/* Modal Forms */

$blue: #0ab6d3;

.modal {
    // overflow-y: unset;
    min-height: 60vh;

    .modal-footer {
        margin-top: 20%;
    }

    .dropdown-content li > a,
    .dropdown-content li > span {
        color: #333;
        font-size: 12px;
        padding: 14px 16px;
    }

    .select-wrapper input.select-dropdown {
        font-size: 13px;
    }

    .select-wrapper input.select-dropdown:focus {
        border-bottom: 1px solid $blue;
    }

    .select-wrapper + label {
        font-size: 0.9rem;
    }

    /* label focus color */
    #select-member-wrapper input[type='text']:focus + label {
        color: #b9b9b9 !important;
    }

    /* label underline focus color */
    #select-member-wrapper input[type='text']:focus {
        border-bottom: 1px solid $blue !important;
        box-shadow: 0 1px 0 0 $blue !important;
    }

    input[type='text']:not(.browser-default) {
        font-size: 13px;
    }
}

/* End Modal Forms */

/* Select Dropdown */

#dropdown-input .input-field {
    .select-wrapper .dropdown-content.select-dropdown {
        top: 50px !important;
    }
}

.list-members {
    img {
        width: 40px;
        height: auto;
    }

    .material-icons {
        position: relative;
        bottom: 17px;
    }

    div {
        display: inline-block;
        padding: 0 10px;
        font-size: 12px;

        .list-members-id {
            color: #7f7f7f;
        }
    }
}

#selected-members {
    svg {
        height: 100px !important;
        margin-top: 50px;
    }
}

.selected-member {
    margin: 20px 0;

    img {
        width: 40px;
        height: auto;
        position: relative;
        top: 18.8px;
    }

    div {
        display: inline-block;
        padding: 0 20px;
        font-size: 13px;

        .selected-member-email {
            color: #7f7f7f;
        }
    }

    .material-icons {
        position: relative;
        top: 26px;
    }

    .material-icons.remove-member:hover {
        cursor: pointer;
    }
}

/* End Select Dropdown */

/* Autocomplete */

.MuiAutocomplete-option {
    font-size: 13px !important;
    overflow-x: visible;
    min-width: 300px !important;
}

/* Autocomplete */

/* Modal */

#deletepermission-modal.modal {
    text-align: left;
    min-height: auto;
}

/* End Modal */
