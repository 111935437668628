/* Home */

#home-message {
    padding-top: 30px;

    h5 {
        font-weight: 500;
    }
}

#main-section {
    padding-top: 120px;
}

/* End Home */

/* Forms */

.search-form {
    .input-field {
        border: none;

        label {
            color: #444;
        }
    }

    button {
        margin: 10px 0 0 10px;
        background: #444;
        text-transform: none;

        &:hover,
        &:focus {
            background: #444;
            color: #ddd;
        }
    }

    input.valid:not([type]),
    input.valid:not([type]):focus,
    input.valid[type='text']:not(.browser-default),
    input.valid[type='text']:not(.browser-default):focus,
    textarea.materialize-textarea.valid,
    textarea.materialize-textarea.valid:focus,
    .select-wrapper.valid > input.select-dropdown,
    input[type='text']:not(.browser-default):focus:not([readonly]) {
        border-bottom: 1px solid #444;
        box-shadow: 0 1px 0 0 #444;
    }

    input[type='text']:not(.browser-default):focus:not([readonly]) + label {
        color: #444;
    }
}

/* End Forms */

/* Table */

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
    display: none;
}

.MuiTableContainer-root {
    min-height: 770px;
}

.MuiTableRow-root.MuiTableRow-hover .MuiTableCell-root {
    height: 75px;
}

.MuiTableCell-root {
    line-height: 2 !important;

    &:hover {
        cursor: pointer;
    }

    .material-icons {
        font-size: 18px;
        padding: 0 3px 3px 0;
    }
    .gravatar-icon {
        width: 7.5%;
        max-width: 20px;
        min-width: 12px;
        height: auto;
        margin-right: 2px;
    }
}

.MuiTableCell-head {
    font-weight: bold !important;
}

.group-headers {
    box-shadow: inset 0 -1.5px 0 0 #444, inset 0 0.5px 0 0 #444;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */

    td {
        text-transform: capitalize;
        font-weight: bold;
        line-height: 1.1 !important;
    }
}

/* End Table */

/* Media Queries */

@media only screen and (max-width: 1100px) {
    #main-section .col.l6 {
        width: 100%;
    }
}

/* End Media Queries */
