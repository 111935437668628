/* General styling */

:root {
  --white-header: #d1d2d4;
  --white-subheader: #9b9fa2;
  --black-header: #1d1d21;
  --grey-header: #a7a6ae;
  --background-color: #f4f5f8;
  --info-color: #6d6875;
  --darkmode-white: #ececec;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

// for spacing between sidebar and body (materializecss)
nav,
footer {
  padding-left: 150px;
}

.container {
  position: absolute;
  left: 400px;
}

.material-icons {
  display: inline-flex;
  vertical-align: middle;
}

@media only screen and (max-width: 992px) {
  nav,
  footer {
    padding-left: 0;
  }
  .container {
    left: 0;
    right: 0;
  }
  nav .nav-wrapper img {
    margin-left: 0px;
  }
}

.btn-black {
  background: #444;
  text-transform: none;

  &:hover,
  &:focus {
    background: #444;
    color: #ddd;
  }
}

/* End General styling */

/* Spinner */

.spinner {
  text-align: center;
  position: relative;
  top: 20vh;

  .spinner-text {
    position: relative;
    bottom: 40px;
  }

  .loader__dot {
    animation: 1s blink infinite;
  }
  .loader__dot:nth-child(2) {
    animation-delay: 250ms;
  }
  .loader__dot:nth-child(3) {
    animation-delay: 500ms;
  }

  @keyframes blink {
    50% {
      color: transparent;
    }
  }
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
  margin: 0 auto;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--black-header);
  border-radius: 100%;
  animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

/* End Spinner */
